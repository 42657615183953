import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import Wrapper from "../layout/wrapper/wrapper"
import TextHero from "../../components/text-hero/text-hero"
import BlogPostMeta from "../../components/blog-post-meta/blog-post-meta"
import BlogLatestPosts from "../../components/blog-latest-posts/blog-latest-posts"
import Styles from "./blog-post.module.scss"
import { linkResolver } from "../../utils/linkResolver"
import htmlSerializer from "../../utils/htmlSerializer"

const BlogPost = props => {
  const {
    title,
    body,
    firstPublicationDate,
    publicationDate,
    author,
    latestPosts,
  } = props

  return (
    <>
      <Wrapper>
        <TextHero title={title} />
        <div className={Styles.blogPostContentWrapper}>
          <section className={Styles.postContent}>
            <BlogPostMeta
              author={author}
              date={publicationDate || firstPublicationDate}
            />
            <div className={Styles.postContentBody}>
              <RichText
                render={body}
                linkResolver={linkResolver}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </section>
          <BlogLatestPosts posts={latestPosts} />
        </div>
      </Wrapper>
    </>
  )
}

BlogPost.propTypes = {
  title: PropTypes.string,
  body: PropTypes.array,
  latestPosts: PropTypes.array,
  firstPublicationDate: PropTypes.string,
  publicationDate: PropTypes.string,
  author: PropTypes.string,
}

export default BlogPost
