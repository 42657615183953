import React from "react"
import PropTypes from "prop-types"
import Styles from "./blog-latest-posts.module.scss"
import { Link } from "gatsby"

const BlogLatestPosts = props => {
  const { posts } = props

  return (
    <div className={Styles.blogLatestPosts}>
      <h2>Latest posts</h2>
      {posts.map(post => {
        const { title, _meta } = post.node
        const { uid } = _meta
        return (
          <Link to={`/blog-post/${uid}`} key={uid}>
            <h3 className={Styles.blogLatestPosts__title}>{title}</h3>
          </Link>
        )
      })}
    </div>
  )
}

BlogLatestPosts.propTypes = {
  posts: PropTypes.array,
}

export default BlogLatestPosts
